
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _2048l4dQF6OItCUzj_45VClRhTANd_MTmgaA07MZF3BskF_MsMeta } from "/home/runner/work/website2/website2/pages/2048.vue?macro=true";
import { default as PongpHbwCRPplI4hMBLBL7M7j2NJwGqPVasxLOs6vk4d5LMMeta } from "/home/runner/work/website2/website2/pages/Pong.vue?macro=true";
import { default as gamesgheWk_45FR7QcfedUk3tbqCzmzEN66mGnR_454JqX7kp2CcMeta } from "/home/runner/work/website2/website2/pages/games.vue?macro=true";
import { default as index79oTZJ13qVDOls49JiEiY6EzlPRLNPxSZEARz1Vc2W4Meta } from "/home/runner/work/website2/website2/pages/index.vue?macro=true";
import { default as linkszCRNo0rMyIzBqsa0R12mYeJ4ZbHqDIDBh1TNRPgvTToMeta } from "/home/runner/work/website2/website2/pages/links.vue?macro=true";
import { default as _91id_93UsQ_459e7RC4fFLFv_45euW4pXSk0WHMEovQ932f8V2GGC4Meta } from "/home/runner/work/website2/website2/pages/blog/[id].vue?macro=true";
import { default as indexnW_Q1NAXo8Bo8azs_45MC4EDmv8pFgtyqevWxz5By8NzQMeta } from "/home/runner/work/website2/website2/pages/blog/index.vue?macro=true";
import { default as CodeEditorYRi_K8gpwoCpHwIJVXJAxBsE88BO5SAQboOHQUdpijIMeta } from "/home/runner/work/website2/website2/pages/CodeEditor.vue?macro=true";
import { default as CatAndMouseTyR_45rMNxmC7A2_tDccK5EmQqjtnsxkpikbnPcTfImXsMeta } from "/home/runner/work/website2/website2/pages/CatAndMouse.vue?macro=true";
export default [
  {
    name: "2048",
    path: "/2048",
    component: () => import("/home/runner/work/website2/website2/pages/2048.vue")
  },
  {
    name: "Pong",
    path: "/Pong",
    component: () => import("/home/runner/work/website2/website2/pages/Pong.vue")
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/home/runner/work/website2/website2/pages/games.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index79oTZJ13qVDOls49JiEiY6EzlPRLNPxSZEARz1Vc2W4Meta || {},
    component: () => import("/home/runner/work/website2/website2/pages/index.vue")
  },
  {
    name: "links",
    path: "/links",
    component: () => import("/home/runner/work/website2/website2/pages/links.vue")
  },
  {
    name: "Blog Post",
    path: "/blog/:id()",
    meta: _91id_93UsQ_459e7RC4fFLFv_45euW4pXSk0WHMEovQ932f8V2GGC4Meta || {},
    component: () => import("/home/runner/work/website2/website2/pages/blog/[id].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/runner/work/website2/website2/pages/blog/index.vue")
  },
  {
    name: "CodeEditor",
    path: "/CodeEditor",
    component: () => import("/home/runner/work/website2/website2/pages/CodeEditor.vue")
  },
  {
    name: "CatAndMouse",
    path: "/CatAndMouse",
    component: () => import("/home/runner/work/website2/website2/pages/CatAndMouse.vue")
  }
]